import { WorkBook, WorkSheet } from 'xlsx';
import * as XLSX from 'xlsx';

export type DimensionRange = [number, number];
export type SheetRange = {
  rowRange: DimensionRange;
  colRange: DimensionRange;
};

export class SheetView {
  sheet: WorkSheet;
  reader: SheetReader;
  columns: string[] = [];
  headerColumn: number;
  dataRange: SheetRange;

  constructor(sheet: WorkSheet, reader: SheetReader) {
    this.sheet = sheet;
    this.reader = reader;

    if(sheet == null || sheet['!ref'] == null) {
      throw new Error("Invalid sheet");
    }

    [this.headerColumn, this.dataRange] = this.calculateDataRange();
    this.columns = this.createColumns();
  }

  private createColumns() {
    const { rowRange, colRange } = this.dataRange;
    const row = rowRange[0] - 1;
    const [colStart, colEnd] = colRange;

    const result = Array(colEnd - colStart);

    for(let col = colStart; col < colEnd; ++col) {
      const address = XLSX.utils.encode_cell({ c: col, r: row });

      const id = this.sheet[address]?.v ?? null;
      result[col - colStart] = String(id).trim();
    }

    return result;
  }

  private calculateDataRange(): [number, SheetRange] {
    const range = XLSX.utils.decode_range(this.sheet['!ref']!);

    const colStart = range.s.c;
    const colEnd = range.e.c + 1;
    const colRange: DimensionRange = [range.s.c, range.e.c + 1];

    const rowEnd = this.findRowEnd(range);

    const maxRow = Math.min(rowEnd, range.s.r + 10);

    for(let row=range.s.r; row < maxRow; ++row) {
      const length = range.e.c - range.s.c + 1;
      let filled = 0;

      for(let col = range.s.c; col <= range.e.c; ++col) {
        if(this.getCell(col, row) != null) {
          filled += 1;
        }
      }

      if(filled / length > 0.5) {
        const rowRange: DimensionRange = [row + 1, rowEnd];
        return [row, { colRange, rowRange }];
      }
    }

    const rowRange: DimensionRange = [range.s.r + 1, rowEnd];
    return [range.s.r, { colRange, rowRange }];
  }

  private findRowEnd(range: XLSX.Range) {
    for(let row = range.e.r; row >= range.s.r; --row) {
      for(let col = range.s.c; col <= range.e.c; ++col) {
        if(this.getCell(col, row) != null) {
          return row + 1;
        }
      }
    }

    return range.s.r;
  }

  private findRowStart(range: XLSX.Range): [number,number] {
    for(let row = range.e.r; row >= range.s.r; --row) {
      const length = range.s.c - range.e.c + 1;
      let filled = 0;

      for(let col = range.s.c; col <= range.e.c; ++col) {
        if(this.getCell(col, row) != null) {
          filled += 1;
        }
      }

      if(filled / length > 0.7) {
        return [row, row + 1];
      }
    }

    return [range.s.r, range.s.r + 1];
  }

  getCell(col: number, row: number) {
    const address = XLSX.utils.encode_cell({ c: col, r: row });
    return this.sheet[address]?.v ?? null;
  }

  getColumnNames(): string[] {
    return this.columns;
  }

  count() {
    const [from, to] = this.dataRange.rowRange;
    return to - from;
  }

  *getRow(row: number): Generator<string|number|null,void,void> {
    const [colStart, colEnd] = this.dataRange.colRange;

    for(let col = colStart; col < colEnd; ++col) {
      const address = XLSX.utils.encode_cell({ c: col, r: row });
      const value = this.sheet[address];
      yield value ? value.v : null;
    }
  }

  *getData(): Generator<Array<string|number|null>,void,void> {
    const [rowStart, rowEnd] = this.dataRange.rowRange;

    for(let row = rowStart; row < rowEnd; ++row) {
      yield [...this.getRow(row)];
    }
  }
}

export class SheetReader {
  workbook: WorkBook;
  fileName?: string;

  constructor(data: any, fileName: string, raw=false) {
    this.workbook = XLSX.read(data, { type: 'binary', raw: raw });
    this.fileName = fileName;
  }

  hasSheet(name: string) {
    return this.workbook.Sheets[name] != null;
  }

  getFirstId() {
    return this.workbook.SheetNames[0];
  }

  readSheets(): Map<string,SheetView> {
    const entries = this.workbook.SheetNames.map((id): [string, SheetView] => {
      //console.log("SHEET START " + id);
      return [id, this.readSheet(id)];
    });

    return new Map(entries);
  }

  readSheet(id?: string): SheetView {
    if(id == null) {
      id = this.workbook.SheetNames[0];
    }

    const sheet = this.workbook.Sheets[id];
    return new SheetView(sheet, this);
  }
};

