import * as React from 'react';
import { useMemo } from 'react';
import { findMapping } from '../file_mapping';
import { loadSheet, loadSheetDeltas } from '../import';
import { SiteData } from '../types';
import { delay } from './helper';

import { ImportDialog, ImportDialogProps, ImportRow } from './import_dialog';

export interface FileDialogProps extends Omit<ImportDialogProps,'data'> {
  file: File;
}

export const FileDialog: React.FC<FileDialogProps> = ({ file, ...other }) => {
  const data = useMemo(async () => {
    // hacky way to make the dialog actually appear while loading
    await delay(200);

    const reader = await loadSheet(file);
    const mapping = findMapping(reader);

    if(mapping == null) {
      throw new Error("Unable to find file mapping");
    }

    const { sheet, fileType } = mapping;

    return {
      deltas: loadSheetDeltas(sheet, fileType),
      comment: `Import file '${file.name}'`,
      merge: fileType.merge,
    };
  }, [file]);

  return (
    <ImportDialog data={data} {...other}>
      <ImportRow name="File name">{file.name}</ImportRow>
    </ImportDialog>
  );
};

