import * as React from 'react';
import { useMemo } from 'react';

import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { useSiteUploads, useUpdateUpload } from './backend';
import { StateIndicator } from './state_indicator';
import { UploadOverviewData } from '../types';

const formatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });

interface UploadsRowProps {
  doImport: (upload: string) => void;
  site: string;
  upload: UploadOverviewData;
};

const UploadsRow: React.FC<UploadsRowProps> = ({ site, upload, doImport }) => {
  const state = upload.pending ? 'warning' : 'okay';
  const mutation = useUpdateUpload();

  const ignore = async () => {
    try {
      mutation.mutateAsync({
        site: site,
        upload: upload.id,
        data: { pending: false },
      });
    } catch(err: any) {
      alert(err.message);
    }
  };

  return (
    <DataTableRow>
      <DataTableCell className="state_cell"><StateIndicator state={state} /></DataTableCell>
      <DataTableCell className="note_cell">{upload.comment || <i>No comment</i>}</DataTableCell>
      <DataTableCell className="user_cell">{upload.userName ?? 'Unknown'}</DataTableCell>
      <DataTableCell className="date_cell">{formatter.format(upload.date)}</DataTableCell>
      <DataTableCell className="action_cell">
        <Button className="import" raised onClick={() => doImport(upload.id)}>Import</Button>
        <Button disabled={!upload.pending || mutation.isLoading} className="ignore" outlined onClick={ignore}>Ignore</Button>
      </DataTableCell>
    </DataTableRow>
  );
};

interface UploadsTableProps {
  id: string;
  doImport: (upload: string) => void;
};

const UploadsTable: React.FC<UploadsTableProps> = ({ id, doImport }) => {
  const uploadsQuery = useSiteUploads(id);

  const uploads = useMemo(() => {
    if(uploadsQuery.data == null) {
      return undefined;
    }

    return uploadsQuery.data.sort((a, b) => +a.date - +b.date);
  }, [uploadsQuery.data]);

  let body;

  if(uploadsQuery.isError) {
    body = (
      <DataTableRow>
        <DataTableCell colSpan={5}>
          Unable to get uploads
        </DataTableCell>
      </DataTableRow>
    );
  } else if(uploads == null) {
    body = (
      <DataTableRow>
        <DataTableCell colSpan={5}>
          Loading ...
        </DataTableCell>
      </DataTableRow>
    );
  } else if(uploads.length === 0) {
    body = (
      <DataTableRow>
        <DataTableCell colSpan={5}>
          No uploads on this site
        </DataTableCell>
      </DataTableRow>
    );
  } else {
    body = uploads.map((upload) => {
      return <UploadsRow key={upload.id} site={id} doImport={doImport} upload={upload} />;
    });
  }

  return (
    <DataTable className="uploads_table" stickyRows={1}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell className="state_cell"></DataTableHeadCell>
            <DataTableHeadCell className="note_cell">Note</DataTableHeadCell>
            <DataTableHeadCell className="user_cell">Uploader</DataTableHeadCell>
            <DataTableHeadCell className="date_cell">Date</DataTableHeadCell>
            <DataTableHeadCell className="action_cell"></DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {body}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};

export interface UploadsListDialogProps {
  id: string;
  doImport: (upload: string) => void;
  onClosed: () => void;
};

export const UploadsListDialog: React.FC<UploadsListDialogProps> = (props) => {
  const { id, onClosed } = props;

  return (
    <Dialog className="upload_dialog" open={true} onClosed={onClosed}>
      <DialogTitle>Uploads</DialogTitle>
      <DialogContent>
        <UploadsTable {...props} />
      </DialogContent>
      <DialogActions>
        <DialogButton isDefaultAction onClick={onClosed}>Done</DialogButton>
      </DialogActions>
    </Dialog>
  );
};
