import * as React from 'react';

import { useState, useEffect, useMemo } from 'react';
import { SheetReader, SheetView } from '../sheet_reader';
import { findMapping } from '../file_mapping';
import { convertNames } from '../convert_names';
import { SiteData, TrackerData } from '../types';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { applyDeltaFile, applyTrackerChanges, calcTrackerChanges, enrichTrackerAddresses, getChangeStats, TrackerDeltaMap } from '../import';
import { useKeycloak } from '@react-keycloak/web';
import usePromise from 'react-use-promise';
import { useUpdateSite } from './backend';

interface ImportDialogWrapperProps {
  cancel: () => void;
  accept?: () => void;
  disabled?: boolean;
}

const ImportDialogWrapper: React.SFC<ImportDialogWrapperProps> = ({ children, cancel, accept, disabled }) => {
  return <Dialog open={true} onClosed={cancel}>
    <DialogTitle>Import Tracker Information</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <DialogButton action="cancel" disabled={disabled} onClick={cancel}>Cancel</DialogButton>
      <DialogButton isDefaultAction disabled={accept == null || disabled} onClick={accept}>Import</DialogButton>
    </DialogActions>
  </Dialog>;
};

export interface ImportRowProps {
  name: string;
};

export const ImportRow: React.FC<ImportRowProps> = ({ name, children }) => {
  return (
    <div className="import_row">
      <span className="key">{name}:</span>
      <span className="value">{children}</span>
    </div>
  );
};

export interface ImportData {
  deltas: TrackerDeltaMap;
  merge: boolean;
  comment: string;
  onSuccess?: () => void;
};

export interface ImportDialogProps {
  site: SiteData;
  data: ImportData | Promise<ImportData>;
  onClosed: () => void;
  allowModeChange?: boolean;
};

export const ImportDialog: React.SFC<ImportDialogProps> = ({ site, data, onClosed, allowModeChange, children }) => {
  const { keycloak: { token } } = useKeycloak();
  const mutation = useUpdateSite();
  const [replace, setReplace] = useState(false);
  const { trackers } = site;

  const [result, error, state] = usePromise(async () => {
    if(token == null) {
      throw new Error("Authorization token not found");
    }

    const base = replace ? [] : trackers;

    const { deltas, merge, comment, onSuccess } = await data;
    const rawChanges = calcTrackerChanges(base, deltas, merge && !replace);
    const changes = await enrichTrackerAddresses(rawChanges, token);
    const result = applyTrackerChanges(base, changes);

    const stats = getChangeStats(changes, base, result);

    return {
      version: site.version,
      deltas,
      stats,
      comment,
      changes,
      result,
      merge,
      onSuccess,
    };
  }, [data, trackers, replace]);

  if(error != null) {
    return <ImportDialogWrapper cancel={onClosed}>{error.message}</ImportDialogWrapper>;
  }

  if(result == null) {
    return <ImportDialogWrapper cancel={onClosed}>Loading ...</ImportDialogWrapper>;
  }

  const doImport = async () => {
    try {
      await mutation.mutateAsync({
        id: site.id,
        comment: result.comment,
        trackers: result.result,
        version: result.version,
      });

      onClosed();

      if(result.onSuccess != null) {
        result.onSuccess();
      }
    } catch {
      alert("Unable to save changes. Please try reloading if the error persists.");
    }
  };

  const handleUpdateReplace: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setReplace(e.target.value === 'true');
  };

  let moreDetails;

  if(replace)  {
    moreDetails = <>
    </>;
  } else if(result.merge) {
    moreDetails = <>
      <ImportRow name="Updates">{result.stats.updates} ({result.stats.unchanged} unchanged)</ImportRow>
      <ImportRow name="Unmatched">{result.deltas.size - result.stats.updates}</ImportRow>
    </>;
  } else {
    moreDetails = <>
      <ImportRow name="Updates">{result.stats.updates} ({result.stats.unchanged} unchanged)</ImportRow>
      <ImportRow name="Insertions">{result.stats.inserts}</ImportRow>
      <ImportRow name="Deletions">{result.stats.deletes}</ImportRow>
    </>;
  }

  let modeChange = null;

  if(allowModeChange) {
    modeChange = (
      <ImportRow name="Mode">
        <Select value={String(replace)} onChange={handleUpdateReplace}>
          <option value="false">Merge into</option>
          <option value="true">Replace all</option>
        </Select>
      </ImportRow>
    );
  }

  return <ImportDialogWrapper disabled={mutation.isLoading} cancel={onClosed} accept={doImport}>
    {modeChange}
    {children}
    <ImportRow name="Entries">{result.deltas.size}</ImportRow>
    {moreDetails}
  </ImportDialogWrapper>;
};

