import { FileMapping } from "./types";

export function convertNames(raw: Array<string|number|null>, fileType: FileMapping) {
  const result: Record<string,string|number|null> = {};
  const { columns } = fileType;

  for(let i = 0; i < columns.length; ++i) {
    const data = raw[i];

    if(data == null) {
      continue;
    }

    const [original, mappings] = columns[i];

    for(const mapping of mappings) {
      result[mapping] = data;
    }
  }

  return result;
}
