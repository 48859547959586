import { TrackerData, StateType, SiteOverviewData } from './types';

const required = ['id', 'name', 'controller', 'lat', 'long'];
const optional = ['address', 'calibration', 'elevation'];

export const getTrackerState = (tracker: TrackerData): StateType => {
  for(const key of required) {
    if(!(key in tracker)) {
      return 'error';
    }
  }

  for(const key of optional) {
    if(!(key in tracker)) {
      return 'warning';
    }
  }

  return 'okay';
};

const stateValueMap = new Map<StateType,number>([
  ['okay', 0],
  ['warning', 1],
  ['error', 2],
]);

const stateIdMap = new Map<number,StateType>([
  [0, 'okay'],
  [1, 'warning'],
  [2, 'error'],
]);

export const getTrackerArrayState = (trackers: TrackerData[]): StateType => {
  if(trackers.length == 0) {
    return 'error';
  }

  const stateValue = trackers.reduce((prev, tracker) => {
    const state = getTrackerState(tracker);
    const value = stateValueMap.get(state)!;
    return Math.max(value, prev);
  }, 0);

  return stateIdMap.get(stateValue)!;
};

export const getTrackerMapState = (trackerMap: Map<string,TrackerData>): StateType => {
  if(trackerMap.size == 0) {
    return 'error';
  }

  return getTrackerArrayState(Array.from(trackerMap.values()));
};

export const getSiteTrackerState = (site: SiteOverviewData): StateType => {
  if(site.trackersMapped == 0) {
    return "error";
  }

  if(site.trackerCount == site.trackersMapped) {
    return "okay";
  }

  return "warning";
};

export const getSitePlanningState = (site: SiteOverviewData): StateType => {
  if(site.trackerCount === 0) {
    return "error";
  }

  return "okay";
};

export const getSiteLiveState = (site: SiteOverviewData): StateType => {
  if(site.qreader == null) {
    return "error";
  } else if(site.webportal == null) {
    return "warning";
  } else {
    return "okay";
  }
};

export const getSiteState = (site: SiteOverviewData) => {
  switch(site.phase) {
    case 'planning':
      return getSitePlanningState(site);

    case 'commission':
      return getSiteTrackerState(site);

    case 'live':
      return getSiteLiveState(site);
  }
}

