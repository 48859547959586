import * as React from 'react';
import { TrackerData } from '../types';

import { Switch } from '@rmwc/switch';
import '@rmwc/switch/styles';

import bearing from '@turf/bearing';
import { point } from '@turf/helpers';

import { MapView, MapMarker, MapLinePoint, MapLine } from './map';
import { UiState } from './ui_state';
import { getTrackerState } from '../state_calc';
import { useMemo, useState } from 'react';
import { ColorGradient } from '../color_gradient';

interface PoleData {
  lat: number;
  lng: number;
  elevation: number;
};

export interface TrackerMapProps {
  trackers: TrackerData[] | undefined;
  uiState: UiState;
};

export const TrackerMap: React.SFC<TrackerMapProps> = React.memo(({ trackers, uiState }) => {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const poleData = useMemo(() => {
    if(trackers == null) {
      return trackers;
    }

    return trackers
      .filter((data) => {
        return data.lat != null && data.long != null;
      })
      .map((data) => {
        const poles = Array<PoleData>();

        const addPole = (lat: number | string | undefined, lng: number | string | undefined, elevation: number | string | undefined) => {
          if(typeof lat !== 'number' || typeof lng !== 'number' || typeof elevation !== 'number') {
            return;
          }

          poles.push({ lat, lng, elevation });
        };

        addPole(data.first_lat, data.first_long, data.first_elevation);
        addPole(data.lat, data.long, data.elevation);
        addPole(data.last_lat, data.last_long, data.last_elevation);

        return {
          ...data,
          poles,
        };
      });
  }, [trackers]);

  const { min, max } = useMemo(() => {
    return (poleData ?? []).reduce((limits, data) => {
      return data.poles.reduce((limits, pole) => {
        return {
          max: Math.max(limits.max, pole.elevation),
          min: Math.min(limits.min, pole.elevation),
        };
      }, limits);
    }, {
      min: Infinity,
      max: -Infinity,
    });
  }, [poleData]);

  if(trackers == null || poleData == null) {
    return null;
  }

  const handleChange = (e: any) => {
    console.log(e.currentTarget);
    console.log(e.currentTarget.checked);
    setIsAdvanced(!!e.currentTarget.checked);
  }

  const control = <>
    <Switch onChange={handleChange} label="Tracker Geometry" />
  </>;

  let payload;

  if(isAdvanced) {
    const distance = Math.max(max - min, 5);

    const gradient = new ColorGradient([
      [min, { r: 0, g: 63, b: 150 }],
      [min + distance / 2, { r: 182, g: 247, b: 252 }],
      [min + distance, { r: 212, g: 66, b: 21 }],
    ]);

    payload = poleData.map((data) => {
      let poleBearing: number | undefined;

      if(data.poles.length > 1) {
        const first = data.poles[0]
        const last = data.poles[data.poles.length-1];
        poleBearing = bearing(point([last.lng, last.lat]), point([first.lng, first.lat]));
      } else {
        poleBearing = undefined;
      }

      const path = data.poles.map((pole, index): MapLinePoint => {
        const edge = index === 0 || index === data.poles.length - 1;

        return {
          lat: pole.lat,
          lng: pole.lng,
          color: gradient.getColorString(pole.elevation),
          title: `\u2191 ${pole.elevation.toFixed(1)}m\n\u21b7 ${poleBearing?.toFixed(1) ?? '-'}\u00b0`,
          scale: edge ? 1.0 : 0.6,
        };
      });

      return <MapLine key={data.id} id={data.id} path={path} color="red" uiState={uiState} />;
    });
  } else {
    payload = trackers
      .filter((data) => {
        return data.lat != null && data.long != null;
      })
      .map((data) => {
        let color: string;

        switch(getTrackerState(data)) {
          case 'error':
            color = '#ff0000';
            break;

          case 'warning':
            color = '#ffae40';
            break;

          case 'okay':
            color = '#00ff00';
            break;
        }

        const position = { lat: Number(data.lat), lng: Number(data.long) };

        return <MapMarker key={data.id} title={String(data.name) ?? data.id} position={position} color={color} id={data.id} uiState={uiState} />;
      });
  }

  return <MapView control={control}>
    {payload}
  </MapView>
});
