import * as React from 'react';
import { useEffect, useState, useMemo, ChangeEvent, useRef, memo } from 'react';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { TrackerData, TrackerDataMap, SelectableStateType } from '../types';
import { getTrackerState } from '../state_calc';
import { UiState, useIsActive } from './ui_state';
import { StateIndicator } from './state_indicator';

interface TrackerRowProps {
  tracker: TrackerData;
  uiState: UiState;
};

const TrackerRow: React.SFC<TrackerRowProps> = memo(({ tracker, uiState }) => {
  const domRef = useRef<HTMLElement>(null);

  const active = useIsActive(tracker.id, uiState);

  useEffect(() => {
    const element = domRef.current;

    if(!active || element == null) {
      return;
    }

    const parent = element.closest('div')!;
    const head = parent.querySelector('th')!;

    if(active && element) {
      const over = parent.scrollTop + head.clientHeight >= element.offsetTop;
      const under = parent.scrollTop + parent.clientHeight <= element.offsetTop + element.clientHeight;

      if(over || under) {
        const y = element.offsetTop + element.clientHeight / 2 - parent.clientHeight / 2;

        parent.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [active]);

  const state = getTrackerState(tracker);


  const setRowActive = () => {
    uiState.toggleActive(tracker.id);
  };

  const handleEnter = () => {
    uiState.setHovered(tracker.id);
  };

  const handleLeave = () => {
    uiState.clearHovered(tracker.id);
  };

  return <DataTableRow ref={domRef} onClick={setRowActive} onMouseOver={handleEnter} onMouseOut={handleLeave} selected={uiState.active === tracker.id}>
    <DataTableCell className="state_cell"><StateIndicator state={state} /></DataTableCell>
    <DataTableCell>{tracker.id}</DataTableCell>
    <DataTableCell>{tracker.name ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.group ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.controller ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.address ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.calibration ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.elevation ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.cleaning ?? '-'}</DataTableCell>
  </DataTableRow>;
});

export interface TrackerListProps {
  trackers: TrackerData[] | undefined;
  empty?: boolean;
  uiState: UiState;
};

export const TrackerList: React.SFC<TrackerListProps> = memo(({ trackers, empty, uiState }) => {
  let body: React.ReactNode;

  if(trackers == null) {
    body = <DataTableRow>
      <DataTableCell colSpan={8}>
        Loading ...
      </DataTableCell>
    </DataTableRow>;
  } else if(empty) {
    body = <DataTableRow>
      <DataTableCell colSpan={8}>
        There are no trackers in this project, yet.
      </DataTableCell>
    </DataTableRow>;
  } else if(trackers.length === 0) {
    body = <DataTableRow>
      <DataTableCell colSpan={8}>
        No trackers matched the criteria.
      </DataTableCell>
    </DataTableRow>;
  } else {
    body = trackers.map((data) => {
      return <TrackerRow key={data.id} tracker={data} uiState={uiState} />;
    });
  }

  return <>
    <DataTable stickyRows={2}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell></DataTableHeadCell>
            <DataTableHeadCell className="table_id">Internal Name</DataTableHeadCell>
            <DataTableHeadCell className="table_tracker">Customer Name</DataTableHeadCell>
            <DataTableHeadCell className="table_group">Group</DataTableHeadCell>
            <DataTableHeadCell className="table_controller">Track.bloxx ID</DataTableHeadCell>
            <DataTableHeadCell className="table_controller">Track.bloxx Address</DataTableHeadCell>
            <DataTableHeadCell className="table_calibration">Calibration</DataTableHeadCell>
            <DataTableHeadCell className="table_elevation">Elevation</DataTableHeadCell>
            <DataTableHeadCell className="table_cleaning">Cleaning</DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {body}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </>;
});
