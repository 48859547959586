import { isEqual } from 'lodash';

import { FileMapping } from './types';
import { SheetReader, SheetView } from './sheet_reader';

const fileTypes: FileMapping[]  = [
    {
      name: "Gantner App Data",
      merge: true,
      columns: [
        ['Tracker', ['id']],
        ['Controller', ['controller']],
        ['Calibration', ['calibration']],
      ],
    },
    {
      name: "Gantner App Data",
      merge: true,
      columns: [
        ['plant', []],
        ['controller_serial', ['controller']],
        ['serial_number', ['id']],
        ['latitude',  ['secondary_lat']],
        ['longitude', ['secondary_long']],
        ['rotation', ['calibration']],
      ],
    },
    {
      name: "Portal Export Data",
      merge: true,
      columns: [
        ['id', ['id']],
        ['name', ['name']],
        ['controller', ['controller']],
        ['address', ['address']],
        ['calibration', ['calibration']],
        ['lat', ['lat']],
        ['long', ['long']],
        ['elevation', ['elevation']],
        ['group', ['group']],
        ['cleaning', ['cleaning']],
      ],
    },
    {
      name: "ZIM Track Commissioning",
      sheet: "Track.bloxx settings",
      merge: false,
      columns: [
        ['ZIM Track Tracker Name', ['id']],
        ['Customer Tracker Name', ['name']],
        ['Drive Unit Serial Nr', ['drive']],
        [/^Track.bloxx GPS Coordinate.*/, ['lat']],
        [null, ['long']],
        [null, ['elevation']],
        ['Group', ['group']],
        [/^Cleaning Position.*/, ['cleaning']],
      ],
    },
    {
      name: "ZIM Track Commissioning",
      sheet: "Track.bloxx settings",
      merge: false,
      columns: [
        ['ZIM Track Tracker Name', ['id']],
        ['Customer Tracker Name', ['name']],
        ['Drive Unit Serial Nr', ['drive']],
        [/^First Pole.*/i, ['first_long']],
        [null, ['first_lat']],
        [null, ['first_elevation']],
        [/^Middle Pole.*/i, ['long']],
        [null, ['lat']],
        [null, ['elevation']],
        [/^Last Pole.*/i, ['last_long']],
        [null, ['last_lat']],
        [null, ['last_elevation']],
        ['Backtracking Group', ['backtracking_group']],
        ['Cleaning Group', ['group']],
        [/^Cleaning Position.*/, ['cleaning']],
      ],
    },
];

const columnsMatch = (mapping: FileMapping, actualColumns: Array<string>) => {
  if(mapping.columns.length > actualColumns.length) {
    return false;
  }

  for(let i = 0; i < mapping.columns.length; ++i) {
    const mappedColumn = mapping.columns[i][0];
    const actualColumn = actualColumns[i];

    if(mappedColumn instanceof RegExp) {
      if(!actualColumn.match(mappedColumn)) {
        //console.log('mismatch', mappedColumn, actualColumn);
        return false;
      }
    } else if(mappedColumn == null) {
      // TODO
    } else {
      if(mappedColumn != actualColumn) {
        //console.log('mismatch', mappedColumn, actualColumn);
        return false;
      }
    }
  }

  return true;
}

export interface FileMappingResult {
  sheet: SheetView;
  fileType: FileMapping;
}

export const findMapping = (reader: SheetReader): FileMappingResult | null => {
  const defaultId = reader.getFirstId();
  const sheets = reader.readSheets();

  //console.log(sheets);

  for(const fileType of fileTypes) {
    //console.log(fileType.name);
    const sheetId = fileType.sheet ?? defaultId;
    const sheet = sheets.get(sheetId);

    //console.log(sheetId);

    if(sheet == null) {
      //console.log(`Sheet ${sheetId} not found`);
      continue;
    }

    const headers = sheet.getColumnNames();

    //console.log(headers);
    const typeColumns = fileType.columns.map(([id, mapped]) => id)
    
    //console.log("COMPARING");
    //console.log(typeColumns);
    //console.log(headers.slice(0, typeColumns.length));

    if(columnsMatch(fileType, headers)) {
      return { fileType, sheet };
    }
  }

  return null;
};

