import * as React from 'react';
import { useState, ChangeEvent, useRef } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { SiteData } from '../types';
import { FileDialog } from './file_dialog';

export interface ImportButtonProps {
  site: SiteData | undefined;
};

export const ImportButton: React.SFC<ImportButtonProps> = ({ site }) => {
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if(files == null) {
      setFile(undefined);
      return;
    }

    setFile(files[0]);
  };

  const resetFile = () => {
    setFile(undefined);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  let dialog = (site != null && file != null) ? <FileDialog site={site} file={file} onClosed={resetFile} allowModeChange /> : null;

  return <>
    {dialog}
    <Button disabled={site == null} className="margin_top" raised onClick={handleClick}>Import File</Button>
    <input style={{ display: 'none' }} ref={fileInputRef} type="file" value="" onChange={onChange} />
  </>;
};
