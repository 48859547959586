import * as XLSX from 'xlsx';
import { SiteData, TrackerData } from './types';
import { saveAs } from 'file-saver';

export interface TrackerBacktrackData {
  id: string;
  poles: [number,number,number][];
}

export const exportTrackerBacktrackData = (trackers: TrackerData[], name: string="export") => {
  const groups = new Map<string,TrackerBacktrackData[]>();

  for(const tracker of trackers) {
    const { id, name, backtracking_group } = tracker;

    if(backtracking_group == null) {
      throw new Error("Tracker missing backtracking group, unable to export");
    }

    const group_id = String(backtracking_group);
    const poles = Array<[number,number,number]>();

    const addPole = (lat: number | string | undefined, long: number | string | undefined, elevation: number | string | undefined) => {
      const pole = [lat, long, elevation];
      console.log(pole);

      for(const value of pole) {
        if(typeof value !== 'number') {
          return;
        }
      }

      poles.push(pole as [number,number,number]);
    };

    addPole(tracker['first_lat'], tracker['first_long'], tracker['first_elevation']);
    addPole(tracker['lat'], tracker['long'], tracker['elevation']);
    addPole(tracker['last_lat'], tracker['last_long'], tracker['last_elevation']);

    const data = {
      id,
      poles,
    };

    if(groups.has(group_id)) {
      groups.get(group_id)!.push(data);
    } else {
      groups.set(group_id, [data]);
    }
  }

  const raw = JSON.stringify(Array.from(groups.values()));
  const blob = new Blob([raw], {type: "application/json"});
  saveAs(blob, `${name}.json`);
}

const cols = ['id', 'name', 'controller', 'address', 'calibration', 'lat', 'long', 'elevation', 'group', 'cleaning'];

export const exportTrackers = (site: SiteData, name="export", type="csv") => {
  if(type === 'json') {
    const data = {
      id: site.id,
      trackers: site.trackers,
    };

    const raw = JSON.stringify(data);
    const blob = new Blob([raw], {type: "application/json"});
    saveAs(blob, `${name}.json`);
  } else {
    const data = site.trackers.map((tracker) => {
      const entries = cols.map((key): [string, unknown] => [key, tracker[key]]);
      return Object.fromEntries(entries);
    });

    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Tracker Data');
    XLSX.writeFile(wb, `${name}.${type}`);
  }
}

