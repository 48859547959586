import { useKeycloak } from '@react-keycloak/web'
import produce from 'immer';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { createSite, deleteSite, getSite, getSiteList, getSiteUploads, updateSite, updateUpload } from '../api';
import { SiteData, SiteOverviewData, UploadData, UploadOverviewData } from '../types';

const siteToOverview = (site: SiteData): SiteOverviewData => {
  const { trackers, ...other } = site;

  const trackersMapped = trackers.reduce((count, tracker): number => {
    if(tracker.address != null) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  return {
    ...other,
    trackerCount: trackers.length,
    trackersMapped,
  }
}

const updateQueryData = <T>(client: QueryClient, id: string | string[], manip: (data: T) => T) => {
  const prev = client.getQueryData<T>(id);

  if(prev == null) {
    return;
  }

  client.setQueryData(id, manip(prev));
}

export const useSiteList = () => {
  const { keycloak } = useKeycloak();

  return useQuery(['sites'], async (): Promise<SiteOverviewData[]> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return getSiteList(keycloak.token);
  });
}

export const useSite = (id: string) => {
  const { keycloak } = useKeycloak();

  return useQuery(['sites', id], async (): Promise<SiteData> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return getSite(id, keycloak.token);
  });
}

export const useSiteUploads = (id: string) => {
  const { keycloak } = useKeycloak();

  return useQuery(['sites_uploads', id], async (): Promise<UploadOverviewData[]> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return getSiteUploads(id, keycloak.token);
  }, {
    retry: 2,
    refetchInterval: 5000,
  });
}

export const useCreateSite = () => {
  const { keycloak } = useKeycloak();
  const client = useQueryClient();

  return useMutation(async (site: Omit<SiteData,'id'>): Promise<string> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return createSite(site, keycloak.token);
  }, {
    onSuccess: (id, site) => {
      updateQueryData<SiteOverviewData[]>(client, ['sites'], (prev) => {
        return produce(prev, (draft) => {
          draft.push({
            ...site,
            id,
            trackerCount: 0,
            trackersMapped: 0,
          });
        });
      });
    },
  });
}

export const useUpdateSite = () => {
  const { keycloak } = useKeycloak();
  const client = useQueryClient();

  return useMutation(async (site: Partial<SiteData> & Pick<SiteData,'id'> & { comment: string }): Promise<SiteData> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return updateSite(site, keycloak.token);
  }, {
    onSuccess: (site, data) => {
      const { id, ...other } = site;

      updateQueryData<SiteOverviewData[]>(client, ['sites'], (prev) => {
        return prev.map((cur) => {
          if(cur.id === id) {
            return siteToOverview(site);
          } else {
            return cur;
          }
        });
      });

      updateQueryData<SiteData>(client, ['sites', id], (prev) => {
        return site;
      });
    },
  });
}

export const useDeleteSite = () => {
  const { keycloak } = useKeycloak();
  const client = useQueryClient();

  return useMutation(async (id: string): Promise<void> => {
    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return deleteSite(id, keycloak.token);
  }, {
    onSuccess: (res, id) => {
      updateQueryData<SiteOverviewData[]>(client, ['sites'], (prev) => {
        return prev.filter(site => site.id !== id);
      });
    },
  });
}

export const useUpdateUpload = () => {
  const { keycloak } = useKeycloak();
  const client = useQueryClient();

  return useMutation(async (params: { site: string, upload: string, data: Partial<Pick<UploadData,'pending'>> }): Promise<UploadData> => {
    const { site, upload, data } = params;

    if(keycloak.token == null) {
      throw new Error("No token available");
    }

    return updateUpload(site, upload, data, keycloak.token);
  }, {
    onSuccess: (result, { site, upload, data }) => {
      updateQueryData<UploadOverviewData[]>(client, ['sites_uploads', site], (prev) => {
        return produce(prev, (draft) => {
          for(const entry of draft) {
            if(entry.id === upload) {
              Object.assign(entry, data);
            }
          }
        });
      });
    },
  });
}
