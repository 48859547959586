import * as React from 'react';
import { useState } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { TextInput } from './form';
import { UiState } from './ui_state';
import { useCreateSite } from './backend';

export interface CreateSiteDialogProps {
  uiState: UiState;
  done: () => void;
};

export const CreateSiteDialog: React.SFC<CreateSiteDialogProps> = ({ uiState, done }) => {
  const [name, setName] = useState('');
  const mutation = useCreateSite();

  const cancel = () => {
    setName('');
    done();
  };

  const accept = async () => {
    try {
      const id = await mutation.mutateAsync({
        name,
        version: 0,
        phase: 'planning',
        trackers: [],
      });

      done();
    } catch {
      alert("Unable to create site");
    }
  };

  const disabled = mutation.isLoading;

  return <Dialog open={true} onClose={cancel}>
    <DialogTitle>Create Site</DialogTitle>
    <DialogContent>
      <TextInput disabled={disabled} value={name} update={setName} label="Name" />
    </DialogContent>
    <DialogActions>
      <DialogButton disabled={disabled} action="cancel">Cancel</DialogButton>
      <DialogButton disabled={disabled} isDefaultAction onClick={accept}>Create</DialogButton>
    </DialogActions>
  </Dialog>;
};

export interface CreateSiteButtonProps {
  uiState: UiState;
};

export const CreateSiteButton: React.SFC<CreateSiteButtonProps> = ({ uiState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const doOpen = () => {
    setIsOpen(true);
  };

  const doClose = () => {
    setIsOpen(false);
  };

  const dialog = isOpen ? <CreateSiteDialog uiState={uiState} done={doClose} /> : null;

  return <>
    {dialog}
    <Button className="create_site_button" onClick={doOpen} outlined>Create Site</Button>
  </>;
};
