import * as React from 'react';

import { Link, Route, Routes, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'

import { SiteView } from './site';
import { Home } from './home';
import { GlobalHeader } from './global_header';
import { useMemo, useState, useEffect } from 'react';
import { Boxes } from './boxes';
import { CalculateAddress } from './calculate_addresses';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';


const SiteRoute: React.SFC = () => {
  const { site } = useParams<{ site: string }>();

  return <SiteView key={site} id={site!} />;
}

const DocumentsRoute: React.SFC = () => {
  return <>Under construction ...</>
};

interface StatusSiteProps {
  apiClient: string;
};

const StatusSite: React.FC<StatusSiteProps> = (props) => {
  const { children } = props;

  return (
    <Layout {...props}>
      <div className="status_site">
        {children}
      </div>
    </Layout>
  );
};

interface LayoutProps {
  apiClient: string;
};

const Layout: React.FC<LayoutProps> = ({ apiClient, children }) => {
  const { keycloak } = useKeycloak();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    keycloak.logout();
  };

  const canManageBoxes = keycloak?.hasResourceRole('boxes_write', apiClient) ?? false;

  return (
    <div className="world">
      <div className="action_top_bar">
        { canManageBoxes ? <Button dense tag={Link} to="/boxes">Box Management</Button> : null }
        <Button dense tag={Link} to="/calcaddress">Address Calculation</Button>
        <Button dense onClick={handleLogout}>Logout</Button>
      </div>
      <GlobalHeader />
      {children}
    </div>
  );
};

export interface AppProps {
  apiClient: string;
}

export const App: React.FC<AppProps> = (props) => {
  const { apiClient } = props;
  const { initialized, keycloak } = useKeycloak();

  if(!initialized) {
    return <StatusSite {...props}>Checking login ...</StatusSite>;
  }

  if(!keycloak.authenticated) {
    return (
      <StatusSite {...props}>
        Not logged in
        <Button raised onClick={() => keycloak.login()}>Login</Button>
      </StatusSite>
    );
  }

  if(!keycloak.hasResourceRole('app_portal', apiClient)) {
    return (
      <StatusSite {...props}>
        Account does not have required privileges
        <button onClick={() => keycloak.logout()}>Logout</button>
      </StatusSite>
    );
  }

  return (
    <Layout {...props}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="site/:site" element={<SiteRoute />} />
        <Route path="boxes" element={<Boxes />} />
        <Route path="calcaddress" element={<CalculateAddress />} />
      </Routes>
    </Layout>
  );
};
