import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useState, useCallback, useEffect, useRef } from 'react';

import { GoogleMap, LoadScript, Marker, Polyline, MarkerProps, OverlayView } from '@react-google-maps/api';

import '../styles/screen';
import { UiState, useIsActive, useIsHovered } from './ui_state';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

export interface MapLinePoint extends google.maps.LatLngLiteral {
  color?: string;
  title?: string;
  scale?: number;
}

interface MapLineProps {
  id: string;
  path: Array<MapLinePoint>;
  color: string;
  uiState: UiState;
};

export const MapLine: React.SFC<MapLineProps> = ({ id, path, color, uiState }) => {
  const nativeLine = useRef<google.maps.Polyline>();

  const active = useIsActive(id, uiState);
  const hovered = useIsHovered(id, uiState);

  useEffect(() => {
    if(!active) {
      return;
    }

    const line = nativeLine.current;

    if(line == null) {
      return;
    }

    // TODO
    const map = line.getMap()! as google.maps.Map;
    const originalBounds = map.getBounds();
    const originalZoom = map.getZoom();

    if(originalBounds == null) {
      return;
    }

    const visible = path.every((position) => originalBounds.contains(position), true)

    if(visible) {
      return;
    }

    const newBounds = new google.maps.LatLngBounds();

    line.getPath().forEach((position) => {
      newBounds.extend(position);
    });

    map.fitBounds(newBounds);
    map.setZoom(Math.min(originalZoom ?? 9, map.getZoom() ?? 9));
  }, [active]);

  let polyWeight: number;
  let strokeColor: string;
  let scale: number;
  let zIndex: number | undefined;

  if(active) {
    scale = 1.2;
    polyWeight = 4;
    strokeColor = '#000';
    zIndex = 888;
  } else if(hovered) {
    scale = 0.75;
    polyWeight = 3;
    strokeColor = '#444';
    zIndex = 999;
  } else {
    scale = 0.5;
    polyWeight = 2;
    strokeColor = '#888';
    zIndex = undefined;
  }

  const handleLoad = (line: google.maps.Polyline) => {
    nativeLine.current = line;
  };

  const handleEnter = () => {
    uiState.setHovered(id);
  };

  const handleLeave = () => {
    uiState.clearHovered(id);
  };

  const handleClick = () => {
    uiState.toggleActive(id);
  };

  const markers = path.map((position, index) => {
    let icon = {
      path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
      //path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
      strokeColor: strokeColor,
      fillColor: position.color ?? '#555',
      fillOpacity: 1.,
      scale: scale * (position.scale ?? 1),
      zIndex: zIndex,
    };

    return <Marker key={index} position={position} zIndex={zIndex} title={position.title} icon={icon} onClick={handleClick} onMouseOver={handleEnter} onMouseOut={handleLeave} />;
  });

  const polyOptions = {
    strokeColor: strokeColor,
    strokeWeight: polyWeight,
    clickable: true,
    zIndex: zIndex,
    optimized: false,
  };

  return <>
    <Polyline options={polyOptions} path={path} onClick={handleClick} onMouseOver={handleEnter} onMouseOut={handleLeave} onLoad={handleLoad} />
    {markers}
  </>;
}

interface MapMarkerProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  color: string;
  title: string;
  id: string;
  uiState: UiState;
};

export const MapMarker: React.SFC<MapMarkerProps> = React.memo(({ position, title, color, id, uiState }) => {
  const nativeMarker = useRef<google.maps.Marker>();

  let opacity: number;
  let scale: number;
  let zIndex: number | undefined;

  const active = useIsActive(id, uiState);
  const hovered = useIsHovered(id, uiState);

  useEffect(() => {
    if(!active) {
      return;
    }

    const marker = nativeMarker.current;

    if(marker == null) {
      return;
    }

    // TODO
    const map = marker.getMap()! as google.maps.Map;

    const position = marker.getPosition();
    const bounds = map.getBounds();

    if(position == null || bounds == null) {
      return;
    }

    const visible = bounds.contains(position)

    if(!visible) {
      map.setCenter(position);
    }
  }, [active]);

  if(active) {
    opacity = 1;
    scale = 1.5;
    zIndex = 888;
  } else if(hovered) {
    opacity = .8;
    scale = 1.3;
    zIndex = 999;
  } else {
    opacity = .5;
    scale = 1;
    zIndex = undefined;
  }

  let icon = {
    path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
    //path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    strokeColor: '#444',
    fillColor: color,
    fillOpacity: opacity,
    strokeWeight: 1,
    scale: scale,
  };

  const handleLoad = (marker: google.maps.Marker) => {
    nativeMarker.current = marker;
  };

  const handleEnter = () => {
    uiState.setHovered(id);
  };

  const handleLeave = () => {
    uiState.clearHovered(id);
  };

  const handleClick = () => {
    uiState.toggleActive(id);
  };

  return <Marker title={title} position={position} zIndex={zIndex} icon={icon} onClick={handleClick} onLoad={handleLoad} onMouseOver={handleEnter} onMouseOut={handleLeave} />;
});

export interface MapViewProps {
  minZoom?: number;
  control?: React.ReactElement;
};

export const MapView: React.SFC<MapViewProps> = React.memo(({ children , minZoom, control}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [initialized, setInitialized] = useState(false);
  const [allowMany, setAllowMany] = useState(false);

  const gotoBounds = useCallback((map: google.maps.Map) => {
    if(map == null) {
      return false;
    }

    let count = 0;
    const bounds = new google.maps.LatLngBounds();

    React.Children.forEach(children, (child) => {
      if(!React.isValidElement(child)) {
        return;
      }

      // TODO more checks?
      const { position, path } = child.props

      if(position) {
        bounds.extend(position);
        count += 1;
      }

      if(path) {
        for(const position of path) {
          bounds.extend(position);
          count += 1;
        }
      }
    });

    if(count === 0) {
      console.log('no stuff');
      return false;
    }

    map.fitBounds(bounds);

    const originalZoom = map.getZoom();
    const zoom = originalZoom ? originalZoom - 1 : 9;
    map.setZoom(zoom);

    setInitialized(true);

    return true;
  }, [map, initialized, children]);

  const onLoad = useCallback((loadedMap: google.maps.Map) => {
    setMap(loadedMap);

    if(!gotoBounds(loadedMap)) {
      loadedMap.setCenter({ lat: 51.427743, lng: 9.910896 });
      loadedMap.setZoom(3);
    }

    if(control != null) {
      const div = document.createElement('div');
      div.classList.add('map_control');
      loadedMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(div);
      ReactDOM.render(control, div);
    }
  }, [gotoBounds]);

  useEffect(() => {
    if(initialized || map == null) {
      return;
    }

    gotoBounds(map);
  }, [initialized, children]);

  if(!allowMany && React.Children.count(children) > 1000) {
    return (
      <div className="map_placeholder">
        <Button outlined onClick={() => setAllowMany(true)}>Show map</Button>
      </div>
    );
  }

  const style = {
    width: "100%",
    //height: "100%",
  };


  const options: google.maps.MapOptions = {
    streetViewControl: false,
    styles: [
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ]
  }

  return <LoadScript
    //googleMapsApiKey="AIzaSyDfxpVugOT3SSbCjRxwYX9rXtI01wrOyoM"
    googleMapsApiKey="AIzaSyDp526RMNlvNmMYSlp2lI5bd7siS4Ncm8M"
  >
    <GoogleMap
      mapContainerStyle={style}
      onLoad={onLoad}
      options={options}
    >
      {children}
    </GoogleMap>
  </LoadScript>;
});
